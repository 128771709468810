import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, getRedirectResult, signInWithRedirect, EmailAuthProvider, linkWithCredential, getAuth, sendEmailVerification } from "firebase/auth";
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { auth, db } from "./Firebase";



function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export default async function signUpUser({fname, lname, email, password, phone, lichessId }) {
  try {

    const auth = getAuth();
    const user = auth.currentUser;
    
    if (user) {
      if (user.emailVerified) {
        console.log("email not verified")
        const credential = EmailAuthProvider.credential(email, password);
        const userUpdated = await linkWithCredential(user, credential)
        await sendEmailVerification(userUpdated.user);
        return ({ status: 700, data: "Please verify your email" });
      }
        
    } else {
      // Create user with email and passwor
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userCreate = userCredential.user;
      await sendEmailVerification(userCreate);
      console.log(userCreate)
      console.log("User signed up and additional details saved to Firestore");
      return ({ status: 200, data: userCreate });
    }

  } catch (error) {
    console.error("Error signing up: ", error);
    return ({ status: 500, data: error.message });
  }
}
    

export const signOutUser = async () =>{
    try {
        const res = await signOut(auth);
        console.log("sign Out");
        return ({status:200, data: res});
    } catch (error) {
        console.error("Error signing out: ", error);
        return ({status:500, data:error.message});
    }
}



// get userDetails on the basis of uid
async function getUserData(userId) {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        console.log("Document data:", userDocSnap.data());
        return userDocSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document:", error);
      throw error;
    }
  }

export const signInUser = async ({email, password}) =>{
    try {
        console.log(email, password);
        const res = await signInWithEmailAndPassword(auth, email, password);
        console.log(res)
        const userId = res.user.uid;
        const userData = await getUserData(userId);
        console.log("SignIn Successfully");
        return ({status:200, userData});
    } catch (error) {
        console.log('error while logging ', error.message);
        return ({status:500, data:error.message});
    }
}


// authentication using google Authentication
export const GoogleAuthentication = async() => {
  const provider = new GoogleAuthProvider();
  let user;
  let token;
  try {
    if (isMobile()) {
      // For mobile: use redirect method
      console.log("REDIRECT FUNTION BEFORE");
      await signInWithRedirect(auth, provider);
      console.log("REDIRECT FUNTION AFTER");
      // The function will stop here due to redirect
      // We need to handle the result separately after redirect
      return;
    } else {
      // For desktop: use popup method
      const result = await signInWithPopup(auth, provider);
      user = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      token = credential?.accessToken;
    }

    console.log("Google Authentication Successful", user);
    return { status: 200, data: user.providerData };
  } catch (error) {
    console.error("Google Authentication Error", error);
    return { status: 400, error: error.message };
  }
}

export const handleRedirectResult = async () => {
  try {
    console.log('handleRedirectResultCalled');
    const result = await getRedirectResult(auth);
    console.log("result of handleRedirect ", result);
    if (result) {
      const user = result.user;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      console.log("Google Authentication Successful", user);
      return { status: 200, data: user.providerData };
    }
  } catch (error) {
    console.error("Google Authentication Error", error);
    return { status: 400, error: error.message };
  }
};