import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import axiosCTC from "../axiosClientCTC";
import { auth } from "../utils/firebase/Firebase";
import axios from "axios";
import CryptoJS from 'crypto-js';
export const AppContext = createContext();

const AppProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [isSigned, setSignIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [accessToken, setAccessToken] = useState(null);
    const [googleSignIn, setGoogleSignIn] = useState(false);
    const [lichessToken, setLichessToken] = useState(null);
    const [lichessId, setLichessId] = useState(null);
    const url = `${process.env.REACT_APP_BACKEND}`;
    const secretKey = 'your-secret-key';

    // Encrypt data
    const encryptData = (data) => {
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    };
    
    // Decrypt data
    const decryptData = (cipherText) => {
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const idToken = await user.getIdToken(true);
                    setAccessToken(idToken);

                    const response = await axios.get(`${url}/api/v1/getUser`, {
                        headers: {
                            'Authorization': `Bearer ${idToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                     
                     setUserData(response.data.user);
                   
                   
                    console.log('contex sigin true' , response.data.user.lichessId)
                    const templichessencry = sessionStorage.getItem("encryptedlichessid")
                    const lichessidcurr = decryptData(templichessencry)
                    console.log(lichessidcurr , 'curr lichess')
                    if( response.data && response.data.user.lichessId && lichessidcurr){
                        if( lichessidcurr == response.data.user.lichessId ){
                            setSignIn(true);
                            console.log("same")
                        }
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    setUserData({});
                    setSignIn(false);
                }
            } else {
                setSignIn(false);
                setAccessToken(null);
                setUserData({});
            }
        });

        return () => unsubscribe();
    }, []); // Dependency array should be empty to avoid unnecessary re-renders

    const value = {
        open,
        setOpen,
        isSigned,
        setSignIn,
        isLoading,
        setIsLoading,
        userData,
        setUserData,
        accessToken,
        setAccessToken,
        googleSignIn,
        setGoogleSignIn,
        lichessToken,
        setLichessToken,
        lichessId, setLichessId
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const getUser = async (accessToken) => {
    try {
        const response = await axiosCTC('/getUser', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        if (response.status !== 200) {
            throw new Error('Failed to fetch user data');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        return null;
    }
};

export default AppProvider;
